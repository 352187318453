.mixin-motion-link
{
    display:inline-block;
    cursor:pointer;
    @include cursor-top;
    @include transform(translate3d(0,0,0));

    svg
    {
        @include transform(translate3d(0,0,0));
    }
}