article.page-stories
{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;

    .nav
    {
        position:absolute;
        width:100%;
        top:27px;
        text-align:center;
        z-index:1;



        @include user-select(none);
        @include transition(height .3s $ease-out-quad,transform .3s $ease-out-quad);

        .wrapper
        {
            position:relative;
            width:100%;
            height:100%;
        }

        .area
        {
            width:50px;

            cursor:pointer;
            display:inline-block;
            vertical-align:top;
            height:44px;

            .bg,.progress
            {
                position:absolute;
                width:100%;
                top:21px;
                height:2px;
                background:white;
            }

            .bg
            {
                background:white;
                opacity:.4;
                @include transition(opacity .6s $ease-out-quad);
            }

            .progress
            {
                width:0;
                display:none;
            }

            &.selected
            {
                .bg
                {
                    opacity:.8;
                }

                .progress
                {
                    display:block;
                }

                cursor:default;
            }
        }

        &:not(.selected)
        {
            &:hover
            {
                //height:.5em;
                opacity:1;
                //@include transform(translate(0, .25em));

                .bg
                {
                    opacity:.6;
                }
            }
        }
    }

    .video
    {
        position:absolute;
        top:0;
        width:100vw;
        height:100%;

        video
        {
            width:100%;
            height:100%;
            object-fit:cover;
        }
    }
}
