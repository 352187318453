.header-page
{
    width:100%;
    min-height:56.25vw;
    //background:$color-background-unimportant;

    padding-top:80px;
    padding-bottom:80px;
    display:flex;
    *
    {
        line-height:1.67;
    }

    .row
    {
        height:100%;
    }

    @include cursor-down;
    @include margin-quarter-bottom;
}