article.nav-work-list
{
    .menu-scroll-container
    {

    }

    .menu-scroll-content-wrapper
    {
        position:relative;
        width:100vw;
        height:100vh;

        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        -ms-flex-pack: center;
    }

    .item-list
    {
        white-space:nowrap;
        flex: 1;
        @include user-select(none);
        .item
        {
            position:relative;
            cursor:pointer;
            @include cursor-top;

            width:calc(23vw);
            margin-left:5vw;
            margin-right:5vw;
           // overflow:hidden;

            vertical-align:top;
            display:inline-block;

            img
            {
                @include user-select(none);
                width:100%;
            }

            h5
            {
                position:absolute;
                margin:15px;
                text-align:left;
                white-space:normal;
                color: $color-invert;
            }

            .item-container
            {
                position:relative;
            }

            @include max-screen($screen-sm-max)
            {
                margin-left:15vw;
                margin-right:15vw;
                width:calc(70vw);
            }
        }
    }


    .indicator
    {
        margin-top:47vh;
        right:10%;
        text-align:right;
        white-space:nowrap;
        top:calc(33vw * 0.5625 *-.5);
        position:absolute;
        opacity:0;

        & > *
        {
            white-space:nowrap;
            display:inline-block;
        }

        .area
        {
            width:32px;
            height:2px;
            display:inline-block;
            position:relative;
            top:-2px;

            .wrapper
            {
                position:relative;
                width:100%;
                height:100%;
            }

            .bg,.progress
            {
                position:absolute;
                width:100%;
                height:100%;
                background:white;
            }

            .bg
            {
                background:white;
                opacity:.4;
                @include transition(opacity .6s $ease-out-quad);
            }

            .progress
            {
                opacity:1;
                width:0;
            }

            &.selected
            {
                .bg
                {
                    opacity:.8;
                }

                .progress
                {
                    display:block;
                }

                cursor:default;
            }
        }

        @include max-screen($screen-sm-max)
        {
            display:none;
        }
    }

    .active
    {
        margin-top:47vh;
        left:10%;
        top:calc(33vw * 0.5625 *-.5);
        position:absolute;
        opacity:0;

        @include max-screen($screen-sm-max)
        {
            display:none;
        }
    }
}
