.header-detail
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    //height: 100vh;
    height: 100%;

    .image-layer
    {
        width: 100%;
        height: 100%;

        //> img
        //{
        //    width: 100%;
        //}

        .main-clip
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            will-change: clip;




            .background-image
            {
                position: relative;
                width: 100%;
                height: 100%;
                top: 0%;
                left: 0%;

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }

            @include min-screen($screen-sm)
            {
                clip: rect($padding-vertical 83vw calc(100vh - #{$padding-vertical}) 17vw);
            }
        }

        .copy-layer
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            > .row
            {
                position: relative;
                height: 100%;

                .title
                {
                    position: absolute;
                    top: calc(50vh - 40px - 2 * #{$copy-line-height} * 20px);
                    left: 0;
                    text-transform: none;

                    @include max-screen($screen-sm-max)
                    {
                        font-size: 40px;
                        line-height: 40px;
                        letter-spacing: -1.8px;
                    }
                }

                .description
                {
                    position: absolute;
                    top: calc(85vh - 2 * #{$copy-line-height} * 20px);
                    //top: calc(75vh - 2 * #{$copy-line-height} * 20px);
                    left: 0;
                }
            }
        }

    }

    @include max-screen($screen-sm)
    {
        .header-intro
        {
            svg
            {
                width: 100%;
            }
        }
    }
}