header
{
    position: relative;
    margin: 0 auto;

    top: 0;
    z-index: 1001;
    @include user-select(none);

    nav
    {
        position: relative;
    }

    #logo
    {
        position: absolute;
        left: 5%;
        top: 20px;
        @include cursor-top;

        .default
        {
            position: absolute;
            top: 0;
        }

        .menu-open
        {
            position: absolute;
            top: 0;
            opacity: 0;
            visibility: hidden;
          //  display: none;
        }
    }

    #menu
    {
        position: absolute;

        right: calc(5% + 55px);
        top: 17px;

        cursor: pointer;
        @include cursor-top;

        .default
        {
            position: absolute;
            top: 0;
        }

        .menu-open
        {
            position: absolute;
            top: 0;
            opacity: 0;
            visibility: hidden;
            display: none;
        }
    }
}

.stories
{
    header
    {
        #logo,#menu
        {
            .default
            {
                opacity: 0;
                visibility: hidden;
                display: none;
            }

            .menu-open
            {
                opacity: 1;
                visibility: visible;
                display: block;
            }
        }
    }
}

.mobile-nav
{
    position: fixed;
    height: 100%;
    width: 100vw;
    z-index: 1000;
    @include user-select(none);
    top: 0;
    display: none;

    text-align: center;

    .table
    {
        width: 100%;
        height: 100%;
    }

    .animation-layer
    {
        position:absolute;
        top:0;

        & > *
        {
            position:absolute;
            top:0;
            left:0;
            display:none;
            opacity:0;
            visibility:hidden;
        }

        svg
        {
            width:100vw;
            height:100vh;
            background:rgba(0,0,0,.8);
        }
    }


    #about
    {
        position: absolute;
        bottom: 10px;
        left: calc(5% + 1px);
    }

    #home
    {
        position: absolute;
        top: 17px;
        left: 50%;
    }

    #contact
    {
        position: absolute;
        right: calc(5% - 5px);
        bottom: 10px;
    }
}