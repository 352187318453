.slide
{
    overflow: hidden;
    position: relative;
    display: block;
    float: left;
    width: 100vw;
    // height is overriden in javascript
    height: 100vh;

    &.slide-spacer
    {
        background-color: transparent;
    }

    .slide-inner
    {
        position: relative;
        height: 100%;
        transform: translate3d(50%, 0, 0);
    }

    .main-clip
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        will-change: clip;


        @include min-screen($screen-sm-max)
        {
            clip: rect(50vh 50vw 50vh 50vw);
        }
    }

    .left-clip
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //clip: rect($padding-vertical calc(100vw - #{$padding-horizontal}) calc(100vh - #{$padding-vertical}) $padding-horizontal);
        // funzt im ios safari nicht deshalb auch in slider-item.js
        clip: rect($padding-vertical 50vw calc(100vh - #{$padding-vertical}) $padding-horizontal);

        .left-flip
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";

            img.project-image-left {}
        }

        @include max-screen($screen-sm)
        {
           display:none;
        }
    }

    .right-clip
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // funzt im ios safari nicht deshalb auch in slider-item.js
        clip: rect($padding-vertical calc(100vw - #{$padding-horizontal}) calc(100vh - #{$padding-vertical}) 50vw);

        @include max-screen($screen-sm)
        {
            display:none;
        }
    }

    .background-image
    {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0%;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .copy-layer
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        > .row
        {
            position: relative;
            height: 100%;

            .title
            {
                position: absolute;
                top: calc(50vh - 40px - 2 * #{$copy-line-height} * 20px);
                left: 0;
                text-transform: none;

                @include max-screen($screen-sm-max)
                {
                      font-size: 40px;
                      line-height: 40px;
                      letter-spacing: -1.8px;
                }
            }

            .meta-info
            {
                font-size: 10px;
                text-transform: uppercase;
                letter-spacing: 2px;
                line-height: 2;
            }

            .index
            {
                position: absolute;
                top: calc(16vh + 2 * #{$copy-line-height} * 20px);
                left: 0;
                padding-left:50px;
            }

            .category
            {
                position: absolute;
                top: calc(55vh - 40px - 2 * #{$copy-line-height} * 20px + 80px);
                left: 0;
                padding-left:50px;
            }

            .description
            {
                position: absolute;
                // overriden in javascript
                top: calc(85vh - 2 * #{$copy-line-height} * 20px);
               // bottom:15vh;

                left: 0;
            }

            @include max-screen($screen-sm-max)
            {
                .index,.category
                {
                    display:none;
                }
            }
        }
    }
}

.scale
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    transform: scale(.92);

    @include max-screen($screen-sm)
    {
        transform: scale(.6);
    }
}


.vertical-text
{
    float: left;
    transform: rotate(90deg) translate3d(0, 0, 0);
    transform-origin: left bottom 0;
}