*
{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html, body
{
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

body
{
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 150%;

    font-family: "Gotham A", "Gotham B", "Helvetica", "Arial", "sans-serif";
    font-style: normal;
    font-weight: 700;

    &.nav-open
    {
        //position: fixed;
        overflow: hidden;
        width: 100%;
    }

    &.stories
    {
        background: black;
    }

    &.page
    {
        opacity: 0;
    }
}

@mixin max-width() {
    max-width: 1280px;
    margin: 0 auto;
}

body.post.work
{
    .scroll-container
    {
       // top:100vh;

        @include cursor-down;
    }
}

.scroll-content-wrapper
{
    position: relative;

    width: 100%;
    background: white;
    opacity:1;
    top: 100vh;

    @include cursor-default;
}

.mobile-view
{
    display: none !important;
}

//MOBILE
@include max-screen($screen-sm)
{
    .web-view
    {
        display: none !important;
    }

    .mobile-view
    {
        display: block !important;
    }
}

.max-width
{
    @include max-width();
}

.scroll-container
{
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;

    &.freeze
    {
        pointer-events: none;
    }
}