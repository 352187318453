.header-home
{
    position: absolute;
    width: 100vw;
    height: 100%;

    > *
    {
        height: 100%;

        > *
        {
            height: 100%;
        }
    }

    .logo-animation
    {
        margin: 0 auto;
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 700px;
        opacity:0;

        > *
        {
            width: 100%;
            height: 100%;
        }
    }
}