.project-slider
{
    position: relative;
    width: 100vw;
    height: 100vh;
    height: 559px;
    height: 100%;

    @include cursor-right;

    a
    {
        @include cursor-right;

        .main-clip,.left-clip,.right-clip
        {
            @include cursor-top;
        }

        .copy-layer
        {
            pointer-events:none;
        }
    }

    .slides-container
    {
        //pointer-events: none;
        position: relative;
        width: 100%;
        height: 100%;

        //transform: translate3d(-25%, 0, 0);
    }

    //.scroll-dummy-container
    //{
    //    //pointer-events: none;
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    width: 100%;
    //    height: 100%;
    //
    //    .scroll-dummy-content
    //    {
    //        height: 600vh;
    //    }
    //}
}

//.test-slides
//{
//    position: fixed;
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    width: 100vw;
//    height: 100vh;
//    background-color: #0D6F92;
//    opacity: 0.4;
//}


















.grid
{
    position: relative;
    @include clearfix();
    @include full-margin-width();

    width: 94%;
    margin-top: 80px;

    .table
    {
        table-layout: fixed;

        .table-cell
        {
            vertical-align: top;
        }
    }

    .grid-side
    {
        & > *
        {
            margin-bottom: 60px;
        }
    }

    @include max-screen($screen-sm-max)
    {
        .table-row
        {
            display: block;
        }

        .table-cell
        {
            display: block;
        }
    }

}