@import "vars/settings";

@import "lib/library";

// reset
@import "vars/reset";
@import "vars/normalize";
@import "vars/mixins";
@import "vars/filter";
@import "vars/fonts";
@import "vars/fo-icons";
@import "vars/media-queries";

@import "cursor/cursor";

@import "general/colors";
@import "general/base";
@import "general/text";
@import "general/links";
@import "general/border";
@import "general/margin";
@import "general/block";
@import "general/button";
@import "general/main";

//partials
@import "../partials/partials";

//modules
@import "../modules/modules";

//pods
@import "../pods/pods";

//mixins
@import "../mixins/mixins";

