.item-list
{
    position:relative;


    &.about
    {
        .team-item
        {
            padding-top: 3rem !important;

            @include max-screen($screen-sm-max)
            {
                padding-top: 2rem !important;
            }

            @include margin-bottom;
        }
    }
}